import React from "react"
import PropTypes from "prop-types"

import { subtitleStyles } from "../../utils/styles"

const Subtitle = ({ children, isInverted, customStyles, className }) => {
  return (
    <h2
      css={theme => [
        {
          ...subtitleStyles,
          ...customStyles,
        },
        isInverted && {
          color: theme.colors.white,
        },
      ]}
      className={className}
    >
      {children}
    </h2>
  )
}

Subtitle.propTypes = {
  isInverted: PropTypes.bool,
  color: PropTypes.string,
  textAlign: PropTypes.string,
  customStyles: PropTypes.object,
}

export default Subtitle
