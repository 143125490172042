import React from "react"

import { colors } from "../../../utils/presets"

const Circle = ({ size = 50, color = colors.purple[50], ...delegated }) => {
  return (
    <div css={{ zIndex: -1 }} {...delegated}>
      <svg
        aria-hidden="true"
        viewBox="0 0 50 50"
        style={{
          width: size,
          height: size,
        }}
      >
        <circle cx={25} cy={25} r={25} fill={color} />
      </svg>
    </div>
  )
}

export default Circle
