import React from "react"

import SamePageLink from "./SamePageLink"

const wrapperStyles = theme => ({
  justifyContent: `flex-end`,
  display: `none`,

  [theme.mediaQueries.tablet]: {
    display: `flex`,
  },
})

const listStyles = {
  listStyleType: `none`,
  marginBottom: 0,
}

const listItemStyles = theme => ({
  marginTop: 0,
  marginBottom: theme.space[3],

  ":last-of-type": {
    marginBottom: 0,
  },
})

const MiniTableOfContents = ({ children, ...delegated }) => {
  return (
    <div css={wrapperStyles} {...delegated}>
      <ul css={listStyles}>{children}</ul>
    </div>
  )
}

MiniTableOfContents.Item = ({ id, children }) => {
  return (
    <li css={listItemStyles}>
      <SamePageLink id={id}>{children}</SamePageLink>
    </li>
  )
}

export default MiniTableOfContents
