import React from "react"
import { Link } from "gatsby"
import { MdArrowForward } from "react-icons/md"

const GAP = 50

const wrapperStyles = theme => ({
  paddingTop: theme.space[5],
  gridGap: theme.space[5],
  display: `grid`,
  [theme.mediaQueries.phablet]: {
    paddingTop: theme.space[10],
    gridTemplateColumns: `repeat(auto-fit, minmax(400px, 1fr))`,
    gridAutoRows: `1fr`,
    gridGap: GAP,
  },
})

const gridCellStyles = theme => ({
  [theme.mediaQueries.phablet]: {
    marginBottom: 0,
  },
})

const linkStyles = theme => ({
  display: `block`,
  height: `100%`,
  color: theme.colors.text.primary,
  textDecoration: `none`,
  padding: theme.space[5],
  borderColor: theme.colors.link.border,
  borderWidth: 1,
  borderStyle: `solid`,
  transition: `all ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,
  borderRadius: theme.radii[2],
  [theme.mediaQueries.phablet]: {
    padding: theme.space[8],
  },
  ":hover, :focus": {
    color: theme.colors.lilac,
    borderColor: theme.colors.link.hoverBorder,
  },
})

const headerStyles = { marginBottom: 0, height: `100%` }

const iconStyles = theme => ({
  marginLeft: theme.space[3],
  verticalAlign: `sub`,
})

const JobBlock = ({ job }) => {
  // The tricky thing about this is that we want to show an arrow icon after
  // the title, "Head of Design ->".
  // We don't want the arrow to break on its own line:
  //
  // Head of design
  // ->
  //
  // In these cases, the last work should break with it:
  //
  // Head of
  // design ->
  let titleElem

  const jobWords = job.title.split(" ")
  if (jobWords.length === 1) {
    titleElem = (
      <>
        {job.title}
        <MdArrowForward css={iconStyles} />
      </>
    )
  } else {
    const tail = jobWords[jobWords.length - 1]
    const first = jobWords.slice(0, jobWords.length - 1)

    titleElem = (
      <>
        {first.join(" ")}{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          {tail}
          <MdArrowForward css={iconStyles} />
        </span>
      </>
    )
  }

  return (
    <div key={job.id} css={gridCellStyles}>
      <h3 css={headerStyles}>
        <Link css={linkStyles} to={job.fields.slug}>
          {titleElem}
        </Link>
      </h3>
    </div>
  )
}

const JobBlocks = ({ jobs }) => {
  return (
    <div css={wrapperStyles}>
      {jobs.map(job => (
        <JobBlock key={job.id} job={job} />
      ))}
    </div>
  )
}

export default JobBlocks
