import React from "react"
import { Link } from "gatsby"
import { MdAdd, MdRemove, MdArrowForward } from "react-icons/md"

const wrapperStyles = theme => ({
  display: `grid`,
  gridTemplateColumns: `100%`,
  gridGap: `${theme.space[4]} ${theme.space[8]}`,
  marginTop: theme.space[10],
  marginBottom: theme.space[8],

  [theme.mediaQueries.phablet]: {
    gridTemplateColumns: `repeat(auto-fit, minmax(400px, 1fr))`,
  },
})

const questionWrapper = theme => ({
  position: `relative`,
  borderBottom: `1px solid ${theme.colors.blackFade[10]}`,
  paddingBottom: theme.space[4],
})

const questionRowStyles = theme => ({
  width: "100%",
  display: `flex`,
  justifyContent: `space-between`,
  background: `transparent`,
  border: `none`,
  fontSize: theme.fontSizes[2],
  fontWeight: `bold`,
  textAlign: `left`,
  padding: 0,
  cursor: `pointer`,

  ":hover": {
    color: theme.colors.lilac,
  },
  ":focus": {
    color: theme.colors.lilac,
  },
})

const answerStyles = theme => ({
  paddingTop: theme.space[6],
  fontSize: theme.fontSizes[1],
})

const expandCollapseButtonStyles = theme => ({
  padding: theme.space[4],
  background: `transparent`,
  border: `none`,
  color: theme.colors.grey[60],

  [theme.mediaQueries.phablet]: {
    ":last-of-type": {
      marginRight: `-${theme.space[4]}`,
    },
  },
})

const titleStyles = theme => ({
  paddingRight: theme.space[4],
})

const headerStyles = theme => ({
  display: `flex`,
  justifyContent: `space-between`,
  alignItems: `center`,
  flexDirection: `column`,
  marginTop: theme.space[10],

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,
  },
})

const faqIconStyles = {
  fontSize: 24,
  color: `inherit`,
  opacity: 0.5,
}

const contactLinkStyles = theme => ({
  color: theme.colors.lilac,
  textDecoration: `none`,
  display: `inline-flex`,
  alignItems: `center`,
})

const expandCollapseRowStyles = theme => ({
  marginTop: theme.space[4],
  [theme.mediaQueries.phablet]: {
    marginTop: 0,
  },
})

const Question = ({ title, answer, isExpanded, handleToggle }) => {
  return (
    <div css={questionWrapper}>
      <button
        aria-expanded={isExpanded}
        css={questionRowStyles}
        onClick={handleToggle}
      >
        <div css={titleStyles}>{title}</div>

        <div>
          {isExpanded ? (
            <MdRemove css={faqIconStyles} />
          ) : (
            <MdAdd css={faqIconStyles} />
          )}
        </div>
      </button>
      {isExpanded && <div css={answerStyles}>{answer}</div>}
    </div>
  )
}

const FAQs = ({ questions }) => {
  const [expandedQuestions, setExpandedQuestions] = React.useState({})

  const areAllQuestionsExpanded =
    Object.values(expandedQuestions).filter(shown => shown).length ===
    questions.length

  const toggleExpandAll = () => {
    if (areAllQuestionsExpanded) {
      setExpandedQuestions({})
    } else {
      const allExpandedQuestions = questions.reduce((acc, question) => {
        return {
          ...acc,
          [question.id]: true,
        }
      }, {})

      setExpandedQuestions(allExpandedQuestions)
    }
  }

  return (
    <>
      <header css={headerStyles}>
        <div>
          Can&apos;t find the answer to your question?{" "}
          <Link to="/contact-us/" css={contactLinkStyles}>
            Talk to us{" "}
            <MdArrowForward css={theme => ({ marginLeft: theme.space[2] })} />
          </Link>
        </div>
        <div css={expandCollapseRowStyles}>
          <button css={expandCollapseButtonStyles} onClick={toggleExpandAll}>
            {areAllQuestionsExpanded ? "Collapse all" : "Expand all"}
          </button>
        </div>
      </header>

      <div css={wrapperStyles}>
        {questions.map(question => (
          <Question
            key={question.id}
            title={question.question}
            answer={question.answer.answer}
            isExpanded={!!expandedQuestions[question.id]}
            handleToggle={() =>
              setExpandedQuestions({
                ...expandedQuestions,
                [question.id]: !expandedQuestions[question.id],
              })
            }
          />
        ))}
      </div>
    </>
  )
}

export default FAQs
