import React from "react"

const wrapperStyles = theme => ({
  marginTop: theme.space[15],
  marginBottom: theme.space[8],
})

const headingStyles = theme => ({
  borderTop: `1px solid`,
  paddingTop: theme.space[2],
  fontSize: theme.fontSizes[4],
})

const Heading = ({ id, children }) => {
  return (
    <div css={wrapperStyles}>
      <h2 id={id} css={headingStyles}>
        {children}
      </h2>
    </div>
  )
}

export default Heading
