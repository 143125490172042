import React from "react"
import { MdArrowBack, MdArrowForward } from "react-icons/md"
import { textStyles } from "./styles"

const STEPS = [
  {
    title: "Apply",
    copy:
      "Send in your application and our team will assess if your skills match what we're looking for.",
  },
  {
    title: "Learn",
    copy:
      "Chat with someone from our recruiting team about your background and hear what it's like to work at Gatsby.",
  },
  {
    title: "Geek out",
    copy:
      "Get your nerd on talking about your passions and experiences with 3-5 potential teammates.",
  },
  {
    title: "Make stuff",
    copy:
      "Complete a short paid project or work sample to showcase your amazing talent.",
  },
  {
    title: "Decide",
    copy:
      "Our team will have a discussion to determine if we will extend an offer, and then it's your turn to decide if you want to work with us!",
  },
]

const BUTTON_SPACING = 30
const BUTTON_WIDTH = 130
const ROW_WIDTH = (BUTTON_WIDTH + BUTTON_SPACING) * STEPS.length

const buttonRowStyles = theme => ({
  width: ROW_WIDTH,
  maxWidth: `100%`,
  display: `flex`,
  flexDirection: `column`,

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,
  },
})

const stepButtonStyles = theme => ({
  position: `relative`,
  border: `none`,
  borderTop: `none`,
  marginTop: theme.space[4],
  background: `transparent`,
  textAlign: `left`,
  paddingTop: theme.space[1],
  cursor: `pointer`,
  flex: 1,

  "&:after": {
    content: `""`,
    height: 2,
    position: `absolute`,
    top: -1,
    left: 0,
    width: `100%`,
    background: theme.colors.lilac,
    opacity: 0,
    transition: `opacity 250ms`,
  },

  [theme.mediaQueries.phablet]: {
    borderTop: `1px solid ${theme.colors.blackFade[10]}`,
    marginTop: 0,
  },
})

const activeStepButtonStyles = theme => [
  stepButtonStyles(theme),
  {
    color: theme.colors.purple[60],

    "&&:after": {
      opacity: 1,
    },
  },
]

const paginationRowStyles = {
  display: `flex`,
  justifyContent: `theme.space-between`,
}

const paginationButtonStyles = {
  width: 48,
  height: 48,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  border: `none`,
  background: `transparent`,
  cursor: `pointer`,
  transition: `opacity 250ms`,
}

const wrapperStyles = theme => ({
  marginTop: theme.space[8],

  [theme.mediaQueries.tablet]: {
    marginTop: theme.space[15],
  },
})
const copyStyles = theme => ({
  marginTop: theme.space[12],
})

const mobileStepHeadingStyles = theme => ({
  fontSize: theme.fontSizes[4],
})

const mobileOnly = theme => ({
  [theme.mediaQueries.desktop]: {
    display: `none`,
  },
})

const desktopOnly = theme => ({
  display: `none`,

  [theme.mediaQueries.desktop]: {
    display: `block`,
  },
})

const iconStyles = theme => ({
  color: theme.colors.lilac,
})

const ProcessSteps = () => {
  return (
    <>
      <section css={theme => [desktopOnly(theme), wrapperStyles(theme)]}>
        <ProcessStepsDesktop />
      </section>

      <section css={theme => [mobileOnly(theme), wrapperStyles(theme)]}>
        <ProcessStepsMobile />
      </section>
    </>
  )
}

const ProcessStepsDesktop = () => {
  const [selectedStepIndex, setSelectedStepIndex] = React.useState(0)

  const selectedStep = STEPS[selectedStepIndex]

  const isFirstStepSelected = selectedStepIndex === 0
  const isLastStepSelected = selectedStepIndex === STEPS.length - 1

  return (
    <section css={wrapperStyles}>
      <div css={buttonRowStyles}>
        {STEPS.map((step, index) => (
          <StepButton
            key={index}
            num={index + 1}
            title={step.title}
            isSelected={index === selectedStepIndex}
            onClick={() => setSelectedStepIndex(index)}
          />
        ))}
      </div>
      <div css={theme => [textStyles(theme), copyStyles(theme)]}>
        {selectedStep.copy}
      </div>
      <div css={paginationRowStyles}>
        <button
          css={paginationButtonStyles}
          disabled={isFirstStepSelected}
          onClick={() => setSelectedStepIndex(selectedStepIndex - 1)}
          style={{
            opacity: isFirstStepSelected ? 0 : 1,
            pointerEvents: isFirstStepSelected ? "none" : "auto",
          }}
          aria-label="Back"
        >
          <MdArrowBack size={32} css={iconStyles} />
        </button>
        <button
          css={paginationButtonStyles}
          disabled={isLastStepSelected}
          onClick={() => setSelectedStepIndex(selectedStepIndex + 1)}
          style={{
            opacity: isLastStepSelected ? 0 : 1,
            pointerEvents: isLastStepSelected ? "none" : "auto",
          }}
          aria-label="Forward"
        >
          <MdArrowForward size={32} css={iconStyles} />
        </button>
      </div>
    </section>
  )
}

const ProcessStepsMobile = () => {
  return (
    <div css={wrapperStyles}>
      {STEPS.map((step, index) => (
        <>
          <h4 css={mobileStepHeadingStyles}>
            {index + 1}. {step.title}
          </h4>
          <p css={textStyles}>{step.copy}</p>
        </>
      ))}
    </div>
  )
}

const StepButton = ({ num, title, isSelected, onClick }) => {
  return (
    <button
      css={isSelected ? activeStepButtonStyles : stepButtonStyles}
      onClick={onClick}
    >
      <div>{String(num).padStart(2, 0)}</div>
      <strong>{title}</strong>
    </button>
  )
}

export default ProcessSteps
