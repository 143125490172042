import React from "react"

const wrapperStyles = theme => ({
  display: `grid`,
  gridTemplateColumns: `repeat(auto-fit, minmax(300px, 1fr))`,
  gridGap: 60,
  marginTop: theme.space[8],
  marginBottom: theme.space[8],

  [theme.mediaQueries.phablet]: {
    marginTop: theme.space[15],
  },
})

const benefitWrapperStyles = {
  position: `relative`,
}

const benefitNameStyles = theme => ({
  fontSize: theme.fontSizes[3],
})

const iconStyles = theme => ({
  display: "block",
  marginBottom: theme.space[4],

  [theme.mediaQueries.phablet]: {
    position: "absolute",
    top: 0,
    left: -20,
    transform: `translateX(-100%)`,
  },
})

const Benefit = ({ iconSrc, name, description }) => {
  return (
    <div css={benefitWrapperStyles}>
      <img alt="" role="presentation" src={iconSrc} css={iconStyles} />
      <h3 css={benefitNameStyles}>{name}</h3>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  )
}

const Benefits = ({ benefits }) => {
  return (
    <div css={wrapperStyles}>
      {benefits.map(benefit => (
        <Benefit
          key={benefit.id}
          iconSrc={benefit.icon.file.url}
          description={benefit.description.childMarkdownRemark.html}
          name={benefit.name}
        />
      ))}
    </div>
  )
}

export default Benefits
