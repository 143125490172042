import React from "react"

import staffPhoto1 from "../../assets/careers/staff-photo-1.jpg"
import staffPhoto2 from "../../assets/careers/staff-photo-2.jpg"
import staffPhoto3 from "../../assets/careers/staff-photo-3.jpg"
import staffPhoto4 from "../../assets/careers/staff-photo-4.jpg"
import staffPhoto5 from "../../assets/careers/staff-photo-5.jpg"
import pupperPhoto from "../../assets/careers/pupper-photo.jpg"

const wrapperStyles = theme => ({
  marginTop: theme.space[15],
})

const rowStyles = theme => ({
  display: `flex`,
  flexDirection: `column`,

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,
  },
})

const imageStyles = theme => ({
  width: `100%`,
  marginLeft: 0,
  marginRight: 0,
  borderRadius: theme.radii[3],

  marginBottom: theme.space[8],

  [theme.mediaQueries.phablet]: {
    width: `auto`,
    height: 240,
    marginRight: theme.space[8],
  },
})

const doggoStyles = theme => [
  imageStyles(theme),
  {
    [theme.mediaQueries.phablet]: {
      marginLeft: `calc(480px + ${theme.space[8]})`,
    },
  },
]

const rowOffsetStyles = theme => ({
  [theme.mediaQueries.phablet]: {
    transform: `translateX(-16px)`,
  },
})

const imageOffsetStyles = theme => ({
  [theme.mediaQueries.phablet]: {
    transform: `translateX(-100px)`,
  },
})

const PhotoGrid = () => {
  return (
    <div css={wrapperStyles}>
      <div css={rowStyles}>
        <img
          css={imageStyles}
          src={staffPhoto1}
          alt="Two Gatsby employees manning a conference table"
        />
        <img
          css={imageStyles}
          src={staffPhoto2}
          alt="gatsby employee singing karaoke very poorly"
        />
      </div>
      <div css={theme => [rowStyles(theme), rowOffsetStyles(theme)]}>
        <img
          css={theme => [imageStyles(theme), imageOffsetStyles(theme)]}
          src={staffPhoto3}
          alt="enthusiastic yet confused cofounder"
        />
        <img
          css={imageStyles}
          src={staffPhoto4}
          alt="satisfied looking product designer wearing a delightful red jacket"
        />
        <img
          css={imageStyles}
          src={staffPhoto5}
          alt="developer doing a great job explaining a highly complex problem"
        />
      </div>
      <div css={rowStyles}>
        <img
          css={doggoStyles}
          src={pupperPhoto}
          alt="cute pupper wearing crocodile hoodie"
        />
      </div>
    </div>
  )
}

export default PhotoGrid
